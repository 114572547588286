/* ==========================================================================
   Author: Jeanclaude Stephane
   Contact: jeanclaude.stephane@hotmail.fr
   ========================================================================== */
#menu_quality{
	display:flex;
	text-align:center;
	width:100vw;
	height:100vh;
	align-items: center;
	justify-content: center;
	
}
.qual_choice{
	width:5em;
	height:5em;
	background-color:green;
	margin:20px;
}
.qual_choice:hover{
	transform:scale(1.1);
}
.qual_choice:active{
	transform:scale(0.99);
}
 
body{
	width:100%;
	height:100%;
	overflow:hidden;
}
h1{
	color:#171717;
	cursor:default;
	font-size:1.1em;
	text-align:center;
}
hr {
  width:100%;
}
 #webgl {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }

.elevator_buttons{
	width:2em;
	height:2em;
	background-color: darkslategrey;
	border-radius: 5em;
	border-style: outset;
	text-align: center;
	margin-top: 1em;
	cursor:default;
}
.elevator_buttons:active{
	border-style: inset;
}
.elevator_buttons:hover{
	background-color:dimgray;
}
#elevator_internal_panel{
	right:0;
	position: absolute;
	visibility:hidden;
}
#elev_bluehorse{
	background-image: url();
	background-color: blue;
}

#interaction_info{
	position: absolute;
	z-index:0;
	width:150px;
	height:30px;
	background-color:blue;
	border-radius:5em;
	text-align:center;
	margin-left:40vw;
	margin-top:50vh;
	visibility:hidden;
	color:#FFF;
	cursor:default;
}
#interaction_info.show,#elevator_internal_panel.show{
	z-index:20;
	visibility:visible;
}
#interaction_info.onPress{
	background-color:red;
}
#fullSizeMenu{
	position: absolute;
	z-index:0;
	width:100%;
	height:100%;
	background-color:grey;
	top:0;
	left:0;
	visibility:hidden;
}
#fullSizeMenu.show{
	visibility:visible;
	z-index:70;
}
#fullSizeMenu iframe{
	width:100vw;
	height:100vh;
	
}
#quit{
	position:absolute;
	width:50px;
	height:50px;
	right:0;
	top: 0;
	background-image:url(./images/quit.png);
	background-repeat: no-repeat;
	background-size: cover;
}
#quit:hover{
	opacity:0.5;
}
#quit:active{
	transform: scale(0.9,0.9);
}
#title{
	width:100vw;
	height:5vh;
}
#desc{
	width:100%;
	display:flex;
	flex-direction:row;
}
#image{
	width:50%;
	height:90vh;
	background-color:green;
}
#description{
	width:50%;
	height:90vh;
	background-color:pink;
}

#buttons{
	display:flex;
	position:absolute;
	height:30px;
	right:5px;
	bottom:5px;
	z-index:600;
	opacity:0;
}
#buttons.start{
	animation-name: fade-on-at-start;
	animation-duration:2s;
	animation-fill-mode:forwards;
}

#fullscreen_mode{
	width:30px;
	height:30px;
	background-image: url(./images/icon_fullscreen.png);
	background-repeat: no-repeat;
	background-size: cover;
}
#fullscreen_mode:hover{
	transform:scale(1.2);
}
#fullscreen_mode:active{
	transform:scale(0.9);
}



#control_circle_L,#control_circle_R{
    position: absolute;
    background-image: url(./images/controls_150.png);
    width: 150px;
    height: 150px;
    visibility:hidden;
	z-index:10;
}
#control_circle_R{
	filter: hue-rotate(180deg);
}
#control_tap_L,#control_tap_R{
    position: absolute;
    background-image: url(./images/tap_50.png);
	filter: drop-shadow(4px 4px 2px black);
    width: 50px;
    height: 50px;
    visibility:hidden;
	z-index:10;
}

#control_circle_L.show,#control_circle_R.show,#control_tap_L.show,#control_tap_R.show{
	visibility:visible;
}



*:active {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -moz-tap-highlight-color: rgba(0,0,0,0);
    tap-highlight-color: rgba(0,0,0,0);
}

/*
To delete
*/
#menu{
	position: absolute;
	display: flex;
	flex-direction:column;
	background-color:rgba(255,255,255,0.1);
	z-index:20;
}



p,
label {
    font: 1rem 'Fira Sans', sans-serif;
}
input {
    margin: .4rem;
}
#add_image{
	background-color:#4e3799;
	width:80%;
	margin-left:10%;
	border-radius:0.5em;
}
#add_image:hover,img:hover{
	transform: scale(1.1);
}
.preview{
	
	right:0px; text-align:left;
	background-color:rgba(200,200,250,0.5);
	border-radius:0.1em;
	width:100%;
	text-align:center;

}
img{
	width:6em;
	height:6em;
	
}



#preloader_cont {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color:#000;
    visibility: hidden;
	transform:scale(1);
}
#preload_img{
	position: absolute;
    width: 100%;
    height: 100%;
	background-image: url(./images/preloader_bg.jpg);
	background-repeat: no-repeat;
    background-position: center center;
	background-size: cover;
	transform:scale(0.3);
	
}
#preload_line{
	position: absolute;
	width: 0%;
    height: 1vh;
	top: 70vh;
}
#preloader_cont.finish{
	animation-name: fade_out_on_finish;
	animation-duration:2s;
	animation-fill-mode:forwards;
}

@keyframes fade-on-at-start{
  from {opacity:0;pointer-events:none;}
  to {opacity:1;pointer-events:all;}
}
@keyframes fade_out_on_finish{
  0%   {opacity: 1;}
  100% {opacity: 0;pointer-events:none;visibility:hidden;}
}